<style scoped lang="scss">
    @use "@/scss/styles.scss" as *;
    .content {
        min-height: 100px;
        height: auto;
        width: 500px;
        background-color: white !important;
        position: absolute;
        left: -280px;
        top: -3px;
        border-radius: 5px;
        color: black;
        box-shadow: 2px 2px 10px black;
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 850px;
        padding: 15px 5px 15px 15px;
    }
    .arrow {
        content: '';
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid white;
        position: absolute;
        top: -13px;
        left: -12px;
        z-index: 1;
    }
    .memo-container {
        border-radius: 5px;
        border: 2px solid lightgrey;
    }
    .memo-container > div {
        padding: 2px 15px;
    }
    .memo-header {
        border-radius: 3px 3px 0 0;
    }
    .memo-body {
        border-top: 2px solid lightgrey;
    }
    .memo-footer {
        border-top: 2px solid lightgrey;
        display: flex;
        justify-content: flex-end;
        background-color: #E2DFDF;
        border-radius: 0 0 3px 3px;
    }
    small {
        font-size: 10px;
    }
    .icon-navbar {
        @extend .pointer-cursor;
        text-decoration: none !important;
        padding: 22px 10px 5px 10px;
    }
    .icon-navbar i {
        font-size: 21px;
    }
    #unread-count {
        float: right;
        margin-left: 5px;
        margin-top: 2px;
        transform: translate(-50%, 0) !important;
    }
    .memo-backdrop {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
    }
</style>

<template>
    <div class="memo-backdrop" ref="memo-backdrop" v-show="this.$store.memoStore?.forceOpen" @click.prevent="handleFocusOut()"></div>
    <Popper class="text-xs" ref="popper" :show="shown" :z-index="50">
        <div @click="toggleMemos()">
            <span class="icon-general icon-navbar">
                <i class="fa-solid fa-envelope"
                   v-tooltip data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Mémos" data-bs-trigger="hover"></i>
                <span id="unread-count" class="badge rounded-pill bg-danger" v-if="memos.length > 0">
                    {{ memos.length }}
                    <span class="visually-hidden">unread messages</span>
                </span>
            </span>
        </div>
        <template #content>
            <div ref="memoFocus" tabindex="0">
                <div class="arrow"></div>
                <div class="bg-general content">
                    <div class="row">
                        <h5 class="text-green col-9">Mémos</h5>
                        <div class="btn-group btn-group-sm col-3">
                            <button class="btn btn-light btn-sm" @click.prevent="openSavedMemo()" v-tooltip
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="bottom" data-bs-title="Mémos Sauvegardés" data-bs-trigger="hover"
                            >
                                <i class="fa-solid fa-undo"></i>
                            </button>
                            <button class="btn btn-light btn-sm" @click.prevent="openFutureMemo()" v-tooltip
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="bottom" data-bs-title="Mémos Futures" data-bs-trigger="hover"
                            >
                                <i class="fa-solid fa-list"></i>
                            </button>
                            <button class="btn btn-light btn-sm" @click.prevent="openNewMemo()" v-tooltip
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="bottom" data-bs-title="Nouveau Mémo" data-bs-trigger="hover"
                            >
                                <i class="fa-regular fa-note-sticky"></i>
                            </button>
                        </div>
                    </div>
                    <div class="mt-2">
                        <template v-for="memo in memos">
                            <div class="memo-container mb-2">
                                <div class="memo-header" :class="getTypeClass(memo)">
                                    {{ this.$general.formatDate(memo.date, 'YYYY-MM-DD hh:mm').replace(':', 'h') }}
                                </div>
                                <div class="memo-body">
                                    <div class="font-bold">SUJET: {{ memo.subject }}</div>
                                    <div @click="handleClick(memo, $event)" v-html="memo.content"></div>

                                    <small>{{ this.$general.flipEmployeeNames(memo.employe, 'full_name')?.full_name }}</small>
                                </div>
                                <div class="memo-footer">
                                    <div class="btn-group btn-group-sm">
                                        <button class="btn btn-light btn-sm" v-tooltip data-bs-toggle="tooltip"
                                                @click.prevent.stop="saveMemo(memo)"
                                                data-bs-placement="bottom" data-bs-title="Sauvegarder"
                                                data-bs-trigger="hover"
                                        >
                                            <i class="fa-solid fa-floppy-disk"></i>
                                        </button>
                                        <button class="btn btn-light btn-sm" v-tooltip data-bs-toggle="tooltip"
                                                @click.prevent.stop="readMemo(memo)"
                                                data-bs-placement="bottom" data-bs-title="Lu" data-bs-trigger="hover"
                                                :class="{ 'bg-green' : memo.recipients[0].is_read }"
                                                :disabled="memo.recipients[0].is_read"
                                        >
                                            <i class="fa-solid fa-check"></i>
                                        </button>
                                        <button class="btn btn-light btn-sm" v-if="memo.type.can_accept"
                                                @click.prevent.stop="acceptMemo(memo)"
                                                v-tooltip data-bs-toggle="tooltip" data-bs-placement="bottom"
                                                data-bs-title="Accepter" data-bs-trigger="hover"
                                                :class="{ 'bg-green' : memo.recipients[0].is_accepted }"
                                                :disabled="memo.recipients[0].is_accepted"
                                        >
                                            <i class="fa-regular fa-thumbs-up"></i>
                                        </button>
                                        <button class="btn btn-light btn-sm" v-if="memo.type.can_delete"
                                                @click.prevent.stop="deleteMemo(memo)"
                                                v-tooltip data-bs-toggle="tooltip" data-bs-placement="bottom"
                                                data-bs-title="Détruire le mémo" data-bs-trigger="hover"
                                        >
                                            <i class="fa-solid fa-trash"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </template>
    </Popper>
    <SavedMemo ref="savedMemo"></SavedMemo>
    <FutureMemo ref="futureMemo"></FutureMemo>
    <NewMemo ref="newMemo"></NewMemo>
</template>

<script>
import Popper from "vue3-popper";
import {useMemoStore} from "@/store/memo.js";
import SavedMemo from "@/components/navbar/memo/SavedMemo.vue";
import FutureMemo from "@/components/navbar/memo/FutureMemo.vue";
import NewMemo from "@/components/navbar/memo/NewMemo.vue";

export default {
    name: "Memo",
    components: {
        NewMemo,
        FutureMemo,
        SavedMemo,
        Popper,
    },
    props: {},
    data() {
        return {
            memos: [],
            memoStore: {},
            shown: false,
            ignoreFocus: false,
        }
    },
    computed: {},
    mounted() {
        const memoStore = useMemoStore();

        memoStore.$subscribe((mutation, state) => {
            // This mutation.events is dev only and cannot be used in the prod build. This will cause errors.
            // if (['savedMemo','futureMemo'].includes(mutation.events.key)) { return; }

            this.memos = state.listOfMemo;
            this.shown = state.forceOpen;
            setTimeout(() => { this.shown = null; }, 250);
        });
    },
    methods: {
        toggleMemos() {
            this.$store.memoStore.forceOpen = !this.$store.memoStore.forceOpen;
            // if (this.$store.memoStore.forceOpen) {
            //     setTimeout(() => { this.$refs.memoFocus.focus(); }, 250);
            // }
        },
        reopenMemo() {
            this.toggleMemos();
            setTimeout(() => { this.toggleMemos(); }, 0);
        },
        handleFocusOut() {
            setTimeout(() => {
                this.toggleMemos();
            }, 100);
        },
        getTypeClass(memo) {
            let typeClass = "";

            switch (memo.type.class) {
                case "message":
                    typeClass = "bg-warning";
                    break;
                case "succes":
                    typeClass = "bg-success text-white";
                    break;
                case "erreur":
                    typeClass = "bg-danger text-white";
                    break;
                case "commande":
                    typeClass = "bg-supplying text-white";
                    break;
                case "action":
                    typeClass = "bg-primary text-white";
                    break;
                default:
                    break;
            }

            return typeClass;
        },
        closeMemo() {
            this.$store.memoStore.setForceOpen(false);
            this.ignoreFocus = true;
            setTimeout(() => { this.shown = null; this.ignoreFocus = false; }, 500);
        },
        openSavedMemo() {
            this.closeMemo();
            this.$refs.savedMemo.open();
        },
        openFutureMemo() {
            this.closeMemo();
            this.$refs.futureMemo.open();
        },
        openNewMemo() {
            this.closeMemo();
            this.$refs.newMemo.open();
        },
        async saveMemo(memo) {
            memo.recipients[0].is_saved = true;
            await this.$store.memoStore.updateRecipient(memo);
            this.reopenMemo();
        },
        async deleteMemo(memo) {
            memo.recipients[0].is_deleted = true;
            await this.$store.memoStore.updateRecipient(memo);
            this.reopenMemo();
        },
        async readMemo(memo) {
            memo.recipients[0].is_read = true;
            await this.$store.memoStore.updateRecipient(memo);
        },
        async acceptMemo(memo) {
            memo.recipients[0].is_accepted = true;
            memo.recipients[0].is_deleted = true;
            await this.$store.memoStore.updateRecipient(memo, this.$route.name, 'acceptMemo');
            this.reopenMemo();
        },
        async readAndDeleteMemo(memo) {
            memo.recipients[0].is_read = true;
            memo.recipients[0].is_deleted = true;
            await this.$store.memoStore.updateRecipient(memo);
            this.closeMemo();
        },
        handleClick(memo, event) {
            // Used to call the printReception method on the frontend side for the BR in memos
            if (event.target.id.includes('reception_')) {
                this.printReception(event.target.id.replace('reception_', ''));
            }
            if (event.target.id.includes('timesheet_')) {
                this.printTimeSheet(event.target.id.replace('timesheet_', ''));
                this.readAndDeleteMemo(memo);
            }
        },
        printReception(id) {
            this.$axios.pdf('/PrintReception', {id: id}).then((response) => {
                window.open(URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' })), '_blank');
            });
        },
        printTimeSheet(payPeriodStartDate) {
            this.$axios.pdf('/PrintTimesheet', {
                date: this.$general.formatDate(payPeriodStartDate),
                type: null,
                employeId: this.$store.authUserStore.fullUserDetails.id
            }).then((response) => {
                window.open(URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' })), '_blank');
            });
        },
    },
    watch: {},
}
</script>