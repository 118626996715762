<template>
    <Navbar v-if="!$route.meta.hideNavbar && $route.name !== undefined" />
    <div class="app-container">
        <router-view />

        <div class="permission-mode hand-cursor" v-if="showPermissionMode" @click="this.$store.permissionStore.resetPermissions()">
            <span id="show-permission">Mode permissions : <strong>[ {{ this.$store.permissionStore.getViewAsRole }} ]</strong></span>
            <span id="exit-permission">Sortir du mode permissions</span>
        </div>
    </div>
    <ToastComponent ref="toastApp" :is-bottom="true"></ToastComponent>
</template>

<style scoped>
    .app-container {
        /* height: 100vh; */
        padding-top: 7px;
        padding-bottom: 30px;
    }
    .permission-mode {
        position: fixed;
        bottom: 10px;
        right: 10px;
        background-color: #FFC107;
        padding: 3px 8px;
        border-radius: 5px;
        font-size: 11px;
        text-align: center;;
    }
    #exit-permission {
        font-weight: 700;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        visibility: hidden;
        white-space: nowrap;
    }
    .permission-mode:hover #exit-permission {
        visibility: visible;
    }
    .permission-mode:hover #show-permission {
        visibility: hidden;
    }
</style>

<script>
import Navbar from "@/components/navbar/Navbar.vue";
import { useAuthUserStore } from "@/store/auth.js";
import { usePermissionStore } from "@/store/perms.js";
import { useConstantStore } from "@/store/constants.js";
import { useRememberStore } from "@/store/remember.js";
import { useMemoStore } from "@/store/memo.js";
import { useBadgeStore } from "@/store/badge";
import ToastComponent from "@/components/_shared/ToastComponent.vue";

export default {
    name: "Login",
    components: {
        ToastComponent,
        Navbar
    },
    computed: {
        showPermissionMode() {
            return !this.$route.meta.hideNavbar && this.$route.name !== undefined && this.$store.permissionStore?.isUserInViewMode;
        }
    },
    updated() {
        if (this.$store.authUserStore.maintenanceInterval === null) {
            if (this.$store.authUserStore.isLoggedIn && this.$store.authUserStore.isMaintenanceToday) {
                this.$store.authUserStore.maintenanceInterval = setInterval(() => {
                    this.updates();
                }, 30 * 1000) // 30 seconds
            }
        }
    },
    mounted() {
        this.$store.authUserStore = useAuthUserStore();
        this.$store.permissionStore = usePermissionStore();
        this.$store.constantStore = useConstantStore();
        this.$store.rememberStore = useRememberStore();
        this.$store.memoStore = useMemoStore();
        this.$store.badgeStore = useBadgeStore();

        this.$store.authUserStore.getMaintenance(true);

        if (this.$store.authUserStore.isLoggedIn) {
            this.$store.authUserStore.setTokenInfo(this.$store.authUserStore.userInfo.token);
            this.$store.permissionStore.getPermissions();
            this.$store.constantStore.getConstants();
            this.$store.constantStore.getHistoryRateKm();
        }

        this.$store.authUserStore.$subscribe((mutation, state) => {
            if (state.isThrottled) {
                this.$refs.toastApp.setEverything(
                    'Erreur',
                    '1 minute',
                    'Vous avez atteint la limite de requêtes.\nVeuillez attendre une minute avant de continuer.',
                    'error'
                );
                this.$refs.toastApp.show();
            }
        });
    },
    methods: {
        updates() {
            const maintenanceToday = { ...this.$store.authUserStore.maintenanceToday};
            const [hourM, minutesM] = this.$general.formatDate(maintenanceToday.start_time, 'hh:mm').split(":").map( val => parseInt(val) );
            const [hourN, minutesN] = this.$general.formatDate(this.$general.getToday(true), 'hh:mm').split(":").map( val => parseInt(val) );
            const minutesLeft = this.$general.getTotalMinutes(hourM, minutesM) - this.$general.getTotalMinutes(hourN, minutesN);

            if ((minutesLeft <= 30 && minutesLeft % 15 === 0) || minutesLeft <= 5) {
                this.$refs.toastApp.setEverything(
                    'Mise à jour Gesware',
                    `dans ${minutesLeft} minutes`,
                    'Vous serez bientôt déconnecté automatiquement.',
                    'info',
                    minutesLeft > 5
                );
                this.$refs.toastApp.show();
            }

            if (minutesLeft <= 0) {
                this.$store.authUserStore.logout();
                this.$router.push({ name: 'Login' });
            }
        }
    }
}
</script>