import {defineStore} from "pinia";

import AxiosClass from "@/classes/axios.js";
import General from "@/classes/general.js";
const axiosConstant = new AxiosClass();
const general = new General();

export const useConstantStore = defineStore('constants', {
    state: () => ({
        listOfConstants: [],
        historyRateKm: [],
    }),
    getters: {

    },
    actions: {
        async getConstants() {
            if (this.listOfConstants.length > 0) return;

            axiosConstant.get('/ConstantList').then((data) => {
                if (data !== undefined) {
                    this.listOfConstants = data;
                }
            });

            this.getHistoryRateKm();
        },
        getConstantByVariable(varName) {
            return { ...this.listOfConstants.find(constant => constant.variable === varName)};
        },
        getHistoryRateKm() {
            if (this.historyRateKm.length > 0) return;

            axiosConstant.get('/HistoryRateKm').then((data) => {
                if (data !== undefined) {
                    this.historyRateKm = data;
                }
            });
        },
        getHistoryRateKmByDate(date) {
            let historySize = this.historyRateKm.length;

            for (let i = 0; i < historySize; i++) {
                if (i < historySize-1 && general.isDateBetween(date, this.historyRateKm[i].date, this.historyRateKm[i + 1].date, '[)'))
                    return {'cost': this.historyRateKm[i].cost, 'sell': this.historyRateKm[i].sell};
            }

            return {'cost': this.historyRateKm[historySize - 1].cost, 'sell': this.historyRateKm[historySize - 1].sell};
        },
        clear () {
            this.$reset();
        },
        async save(variable){
            let returnValue = false;

            await axiosConstant.post('/ConstantSave', {constant: variable}).then((response) => {                
                if (Object.keys(response).length > 0) {
                    this.setConstant(response);
                    returnValue = true;
                }
            });
            return returnValue;
        },
        setConstant(constant) {
            let index = this.listOfConstants.findIndex(c => c.variable === constant.variable);
            this.listOfConstants[index] = { ...constant};
        },  
    },
    persist: {
        storage: sessionStorage,
        paths: ['listOfConstants', 'historyRateKm'],
    },
})