import shelfImage from '@/assets/shelf.svg';

export default class StatusIcon {

    getOrderStatusIcon(item, size = "", showReturn = false) {
        let returnClass = "";
        if([7,8].includes(item?.material_operation_type_id)) {
            if(item?.transaction) {
                item['is_confirmed'] = ![undefined, null].includes(item.transaction?.transaction_return?.transaction_id);
            }

            if(!!item['is_confirmed']) {
                returnClass = ((item?.material_operation_type_id === 7) ? "text-operation" : "inventory-blue");
            }
        }
        switch(item?.material_operation_type_id) {
            case 1:
                return `<i class="fa-solid fa-industry ${size}"></i>`;
            
            case 2:
                return `<i class="fa-solid fa-industry ${((item['is_confirmed']) ? 'text-purple-600' : 'text-operation' )} ${size}"></i>`;

            case 3:
                return `<img class="inventory-img inventory-blue inline-block" src="${shelfImage}" alt="">`;

            case 4:
                return `<i class="fa-solid fa-industry text-green-600 ${size}"></i>`;

            case 5:
                return 'BP';

            case 6:
                return `<i class="fa-solid fa-house-chimney ${((item['is_completed']) ? 'text-general-pale' : 'text-operation' )} ${size}"></i>`;

            case 7:
                if(showReturn) {
                    return '<span class="stack-icon-custom">' +
                                `<i class="fa-solid fa-industry ${((item['is_completed']) ? 'text-general-pale' : returnClass)} ${size}"></i>` +
                                '<i class="fa-solid fa-arrow-right text-red-600 stacked-icon"></i>' +
                            '</span>';
                } else {
                    return '<span class="stack-icon-custom">' +
                                `<i class="fa-solid fa-industry ${size}"></i>` +
                                '<i class="fa-solid fa-arrow-right text-red-600 stacked-icon"></i>' +
                            '</span>';
                }

            case 8:
                if(!showReturn) {
                    return '<span class="stack-icon-custom">' +
                                `<img src="${shelfImage}" class="inventory-img" style="display: inline;" alt="">` +
                                '<i class="fa-solid fa-arrow-right text-red-600 stacked-icon"></i>' +
                            '</span>';
                } else {
                    return '<span class="stack-icon-custom">' +
                                `<img src="${shelfImage}" class="inventory-img ${((item['is_completed']) ? 'inventory-pale' : returnClass)}" style="display: inline;" alt="">` +
                                '<i class="fa-solid fa-arrow-right text-red-600 stacked-icon" style="bottom: -4px;"></i>' +
                            '</span>';
                }

            case -9999:
                return `<i class="fa-solid fa-circle-xmark ${size}"></i>`;

            default:
                return '';
        }
    };

    getShippingStatusIcon(item, size = "", orderStatus = "") {
        if(orderStatus === -9999) {
            return `<i class="fa-solid fa-circle-xmark ${size}"></i>`;
        } else {
            switch(item?.material_operation_type_id) {
                case 9:
                    return `<i class="fa-solid fa-truck ${((item['is_completed']) ? 'text-general-pale' : 'text-green' )} ${size}"></i>`;
    
                case 10:
                    return `<i class="fa-solid fa-tools text-green ${size}"></i>`;
    
                case 11:
                    return '<span class="stack-icon-custom">' +
                                `<i class="fa-solid fa-truck ${((item['is_completed']) ? 'text-general-pale' : 'text-green' )} ${size}"></i>` +
                                '<i class="fa-solid fa-arrow-right text-red-600 stacked-icon"></i>' +
                            '</span>';
    
                default:
                    return '';                            
            }
        }
    };
}