const MaterialOperationTypeEnum = Object.freeze({
    MC: {ID: 1, STEP: 0, STATUS: 1},
    BC: {ID: 2, STEP: 1, STATUS: 2},
    BI: {ID: 3, STEP: 1, STATUS: 2},
    BE: {ID: 4, STEP: 1, STATUS: 2},
    BP: {ID: 5, STEP: 2, STATUS: 3},
    BR: {ID: 6, STEP: 3, STATUS: 4},
    RM: {ID: 7, STEP: 4, STATUS: 7},
    RI: {ID: 8, STEP: 4, STATUS: 7},
    BL: {ID: 9, STEP: 5, STATUS: 5},
    BA: {ID: 10, STEP: 5, STATUS: 5},
    RL: {ID: 11, STEP: 6, STATUS: 6},
    RA: {ID: 12, STEP: 6, STATUS: 6},
});

export default MaterialOperationTypeEnum;